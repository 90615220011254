var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[(_vm.isAccountLoading)?_c('section',[_vm._v("Loading")]):_vm._e(),(!_vm.isAccountLoading)?_c('section',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Caja origen","vid":"Caja origen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Caja origen","placeholder":"Caja origen","autocomplete":""},model:{value:(_vm.input.origin),callback:function ($$v) {_vm.$set(_vm.input, "origin", $$v)},expression:"input.origin"}},_vm._l((_vm.accounts),function(item,index){return _c('div',{key:index,staticClass:"items-select",attrs:{"value":item.id}},[_c('vs-select-item',{attrs:{"value":item.id,"text":("" + (item.name))}})],1)}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Caja destino","vid":"Caja destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Caja destino","placeholder":"Caja destino","autocomplete":""},model:{value:(_vm.input.destiny),callback:function ($$v) {_vm.$set(_vm.input, "destiny", $$v)},expression:"input.destiny"}},_vm._l((_vm.accounts),function(item,index){return _c('div',{key:index,staticClass:"items-select",attrs:{"value":item.id}},[_c('vs-select-item',{attrs:{"value":item.id,"text":("" + (item.name))}})],1)}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{attrs:{"label":"Descripción"},model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Fecha")]),_c('ValidationProvider',{attrs:{"name":"Fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"label":"Fecha","language":_vm.langEs,"name":"start-date","disabled":true},model:{value:(_vm.input.date),callback:function ($$v) {_vm.$set(_vm.input, "date", $$v)},expression:"input.date"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Hora")]),_c('vs-input',{staticClass:"w-full",attrs:{"disabled":true,"type":"time"},model:{value:(_vm.input.time),callback:function ($$v) {_vm.$set(_vm.input, "time", $$v)},expression:"input.time"}})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Monto","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Monto"},model:{value:(_vm.input.amount),callback:function ($$v) {_vm.$set(_vm.input, "amount", $$v)},expression:"input.amount"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full mb-6"},[_c('div',{staticClass:"flex flex-wrap justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"disabled":invalid},on:{"click":_vm.addTransfer}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"mb-2",attrs:{"color":"primary","type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Descartar")])],1)])])]}}],null,false,2100877643)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }