<template>
  <vx-card>
    <section v-if="isAccountLoading">Loading</section>
    <section v-if="!isAccountLoading">
      <ValidationObserver v-slot="{ invalid }">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mb-6">
            <ValidationProvider
              name="Caja origen"
              vid="Caja origen"
              v-slot="{ errors }"
              rules="required"
            >
              <vs-select
                label="Caja origen"
                v-model="input.origin"
                class="w-full"
                placeholder="Caja origen"
                autocomplete
              >
                <div
                  class="items-select"
                  :value="item.id"
                  v-for="(item, index) in accounts"
                  :key="index"
                >
                  <vs-select-item :value="item.id" :text="`${item.name}`" />
                </div>
              </vs-select>
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-6">
            <ValidationProvider
              name="Caja destino"
              vid="Caja destino"
              v-slot="{ errors }"
              rules="required"
            >
              <vs-select
                label="Caja destino"
                v-model="input.destiny"
                class="w-full"
                placeholder="Caja destino"
                autocomplete
              >
                <div
                  class="items-select"
                  :value="item.id"
                  v-for="(item, index) in accounts"
                  :key="index"
                >
                  <vs-select-item :value="item.id" :text="`${item.name}`" />
                </div>
              </vs-select>
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="vx-col w-full">
            <ValidationProvider
              name="Descripción"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-textarea
                label="Descripción"
                v-model="input.description"
              ></vs-textarea>
              <div class="text-danger text-sm leading-tight">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-6">
            <label class="text-sm text-san-juan font-bold">Fecha</label>
            <ValidationProvider
              name="Fecha"
              rules="required"
              v-slot="{ errors }"
            >
              <datepicker
                label="Fecha"
                :language="langEs"
                name="start-date"
                v-model="input.date"
                :disabled="true"
              ></datepicker>
              <div class="text-danger text-sm leading-tight">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-6">
            <label class="text-sm text-san-juan font-bold">Hora</label>
            <vs-input
              :disabled="true"
              class="w-full"
              type="time"
              v-model="input.time"
            />
          </div>
          <div class="vx-col md:w-1/2 w-full mb-6">
            <ValidationProvider
              name="Monto"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                label="Monto"
                v-model="input.amount"
              ></vs-input>
              <div class="text-danger text-sm leading-tight">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>

          <div class="vx-col w-full mb-6">
            <div class="flex flex-wrap justify-end">
              <vs-button
                class="mr-3 mb-2"
                :disabled="invalid"
                @click="addTransfer"
                >Guardar</vs-button
              >
              <vs-button
                @click="$router.go(-1)"
                color="primary"
                type="border"
                class="mb-2"
                >Descartar</vs-button
              >
            </div>
          </div>
        </div>
      </ValidationObserver>
    </section>
  </vx-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { es } from 'vuejs-datepicker/src/locale';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

import { CREATE_TRANSFER_MOVES } from '@/graphql/mutations';

export default {
  data() {
    this._listAccounts();

    return {
      langEs: es,
      input: {
        amount: 0.0,
        description: '',
        date: new Date(),
        time: moment(new Date()).format('HH:mm:ss'),
        origin: '',
        destiny: '',
      },
      accounts: [],
      isAccountLoading: false,
    };
  },
  methods: {
    async _listAccounts() {
      this.isAccountLoading = true;
      try {
        const { data } = await this.$repositories.accounts.listAccounts();
        this.accounts = data;
        this.isAccountLoading = false;
      } catch {
        this.$vs.notify({
          title: 'Error',
          text: 'Error al obtener las cajas.',
          color: 'danger',
        });
        this.isAccountLoading = false;
      }
    },
    addTransfer() {
      this.input.date =
        moment(this.input.date).format('YYYY-MM-DD') + ' ' + this.input.time;
      this.input.amount = parseFloat(this.input.amount);

      console.log('🚀 ~ input', this.input);

      if (this.input.origin == this.input.destiny) {
        this.$vs.notify({
          color: 'warning',
          title: 'Revisa las cajas.',
          text: `Las cajas seleccionadas deben ser diferentes`,
        });
        return;
      }

      this.$apollo.provider.defaultClient
        .mutate({
          mutation: CREATE_TRANSFER_MOVES,
          variables: {
            input: {
              amount: this.input.amount,
              description: this.input.description,
              date: this.input.date,
              origin: this.input.origin,
              destiny: this.input.destiny,
            },
          },
        })
        .then(async () => {
          this.$router.go(-1);
          this.$vs.notify({
            color: 'success',
            title: 'Nota contable creada!',
            text: 'La nota contable fue correctamente creada.',
          });
        })
        .catch((error) => {
          this.$vs.notify({
            color: 'danger',
            title: 'No se pudo crear la nota contable.',
            text: `${error.graphQLErrors[0].message}`,
          });
        });
    },
  },
  components: {
    Datepicker,
    ValidationProvider,
  },
};
</script>
